import styled from "styled-components";

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  right:0;
  z-index: 30;
  background-color: rgba(0,0,0,0.92);
  justify-content: center;
  align-items: center;
`